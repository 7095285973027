<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="skin === 'light'"
          :src="require('@/assets/images/logo/logo-light.png')"
          alt="Logo Bétrancourt"
        />
        <b-img
          v-else
          :src="require('@/assets/images/logo/logo-dark.png')"
          alt="Logo Bétrancourt"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/banner/banner-betrancourt.jpg')"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-img
            v-if="skin === 'light'"
            :src="require('@/assets/images/logo/logo-light.png')"
            alt="Logo Bétrancourt"
          />
          <b-img
            v-else
            :src="require('@/assets/images/logo/logo-dark.png')"
            alt="Logo Bétrancourt"
          />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4"
          >
            {{ $t('WELCOME') }} !
          </b-card-title>
          <b-alert
            variant="danger"
            :show="errorShow"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                {{ $t("ERROR_LOGIN") }}
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="checkLogin()"
            >
              <!-- email -->
              <b-form-group
                label="Email :"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">{{ $t('PASSWORD') }} :</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="loading"
                variant="primary"
                type="button"
                block
                disabled
              >
                <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
              </b-button>
              <b-button
                v-else
                variant="primary"
                type="submit"
                block
              >
                {{ $t('LOGIN_BUTTON') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BAlert, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'

import fr from 'vee-validate/dist/locale/fr.json'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

localize('fr', fr)

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      errorShow: false,
      skin: 'light',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    localStorage.removeItem('internationalisation')
    if (localStorage['vuexy-skin']) this.skin = localStorage['vuexy-skin']
    this.$store.dispatch('localeTraduction/fetchTraductions')
  },
  methods: {
    checkLogin() {
      const that = this
      this.loading = true
      this.errorShow = false

      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          that.$store.dispatch('jwt/checkLogin', { username: that.username, password: that.password })
            .then(({ status, data }) => {
              if (status === 200) {
                localStorage.setItem('accessToken', JSON.stringify(data.token).slice(1, -1))
                localStorage.setItem('refreshToken', JSON.stringify(data.refresh_token).slice(1, -1))
                localStorage.setItem('userData', JSON.stringify(data.userData))
                that.$ability.update(data.userData.ability)

                if (data.userData.langue && data.userData.langue !== '') {
                  that.$i18n.locale = data.userData.langue
                } else {
                  const locale = navigator.language.split('-')[0]
                  if (['fr', 'en'].includes(locale)) {
                    that.$i18n.locale = locale
                  } else {
                    that.$i18n.locale = 'en'
                  }
                }

                that.$router.push({ name: 'home' })
                that.loading = false
              } else {
                that.loading = false
                that.errorShow = true
              }
            })
            .catch(() => {
              that.errorShow = true
              that.loading = false
            })
        } else {
          that.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
